
import { defineComponent, ref, unref, toRefs, onMounted, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ElMessage, ElForm } from 'element-plus';
import useFormErrors from '@/utils/validate';
import { Rules } from 'async-validator';
import { useI18n } from 'vue-i18n';

import ImageUploader, {
  UploadImageEvent
} from '@/components/ImageUploader.vue';

import { useCreatePost, useUpdatePost, usePost } from '@/composables/api';
import { PartialPost, PostStatus, PostSticky } from '@/interfaces/Post';
import { Model } from '@/interfaces/Model';

import Tinymce from '@/components/tinymce/Index.vue';
import {
  IntBoolean,
  MAX_PER_PAGE,
  getModels
} from '@/services/api';

const DEFAULT_FORM_VALUES: PartialPost = {
  title: '',
  cover: ''
};

const rules: Rules = {
  cover: [
    {
      required: true
    }
  ],
  title: [
    {
      required: true
    }
  ],
  content: [
    {
      required: true
    }
  ],
  status: [
    {
      required: true
    }
  ]
};

export default defineComponent({
  props: {
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ImageUploader,
    Tinymce
  },
  setup(props) {
    const { isEdit } = toRefs(props);
    const postId = useRoute().params.id as string;
    const page = useRoute().query.page as string;
    const router = useRouter();

    const formRef = ref<typeof ElForm>();
    const formValues = ref({ ...DEFAULT_FORM_VALUES });
    const { isLoading: isCreatedLoading, mutate: create } = useCreatePost();
    const { isLoading: isUpdatedLoading, mutate: update } = useUpdatePost();
    const { formErrors, bindFormItemError } = useFormErrors();
    const { t } = useI18n();

    const models = ref<Model[]>([]);

    const fetchModels = async() => {
      models.value = (await getModels({ query: { perPage: MAX_PER_PAGE, sortBy: 'name' } })).data;
    };

    onMounted(async() => {
      fetchModels();
    });

    const { data, dataUpdatedAt } = usePost({ postId }, {
      enabled: isEdit.value,
      refetchOnWindowFocus: !isEdit.value
    });
    watch(dataUpdatedAt, () => {
      formValues.value = data.value.data;
    });

    const handleCoverCrop = ({ path }: UploadImageEvent) => {
      formValues.value.cover = path;
    };
    const updateContent = (content: string) => {
      formValues.value.content = content;
    };
    const updateContentCn = (contentCn: string) => {
      formValues.value.contentCn = contentCn;
    };

    const submitForm = () => {
      formErrors.value = {};
      const form = unref(formRef);

      form &&
        form.validate((valid: boolean) => {
          if (valid) {
            if (isEdit.value) {
              update(
                { postId, data: formValues.value },
                {
                  onSuccess() {
                    ElMessage.success({
                      message: 'success!',
                      type: 'success'
                    });

                    router.push({
                      name: 'list-posts'
                    });
                  },
                  onError(error: any) {
                    ElMessage.error({
                      message: error.response?.data.message,
                      type: 'error'
                    });
                    formErrors.value = error.response?.data.errors;
                  }
                }
              );

              return;
            }

            create(
              { data: formValues.value },
              {
                onSuccess() {
                  ElMessage.success({
                    message: 'success!',
                    type: 'success'
                  });

                  formValues.value = DEFAULT_FORM_VALUES;
                  router.push({
                    name: 'list-posts'
                  });
                },
                onError(error: any) {
                  ElMessage.error({
                    message: error.response?.data.message,
                    type: 'error'
                  });
                  formErrors.value = error.response?.data.errors;
                }
              }
            );
          }
        });
    };

    return {
      page,
      formValues,
      formRef,
      rules,
      handleCoverCrop,
      PostStatus,
      IntBoolean,
      PostSticky,
      models,
      isCreatedLoading,
      isUpdatedLoading,
      submitForm,
      updateContent,
      updateContentCn,
      formErrors,
      t,
      bindFormItemError
    };
  }
});
