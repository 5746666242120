import { BaseEntity } from './BaseEntity';
import { PartialModel } from './Model';

export enum PostStatus {
  DRAFT = 'draft',
  PUBLISHED = 'published'
}

export enum PostSticky {
  YES = 1,
  NO = 0
}

export interface Post extends BaseEntity {
  title: string
  titleCn: string
  cover: string
  coverLink: string
  isSticky: PostSticky
  status: PostStatus
  modelIds: number[]
  models: PartialModel[]
  publishedAt: string
  createdAt: string
  content: string
  contentCn: string
  description: string
  descriptionCn: string
}

export type PartialPost = Partial<Post>;
